import Cookies from "js-cookie";

const cookieConsentName = "cookie_consent";
// вернуть чтение из куки при появлении cokie consent
// let cookieConsent = Cookies.get(cookieConsentName);
let cookieConsent = JSON.stringify({
  // functional: true,
  settings: true,
  analytics: true,
  marketing: true,
});

if (document.querySelector(".js-cookie_policy")) {
  document.querySelector(".js-cookie_policy").addEventListener("click", (e) => {
    e.preventDefault();
    if (document.querySelector(".cookie-consent")) {
      // const displayStyle =
      //   document.querySelector(".cookie-consent").style.display == "block"
      //     ? "none"
      //     : "block";
      document.querySelector(".cookie-consent").style.display = "block";
    }
  });
}

const loadScripts = () => {
  if (document.querySelector(".cookie-consent")) {
    document.querySelector(".cookie-consent").style.display = "none";
  }
  const rules = cookieConsent;
  // console.log(rules);
  Object.keys(rules).map((key) => {
    const checkbox = document.querySelector(
      `.cookie-consent-toggles input[type="checkbox"][name="${key}"]`
    );
    if (checkbox) {
      checkbox.checked = rules[key];
    }

    if (rules[key]) {
      document
        .querySelectorAll(`script[data-cookiecategory="${key}"]`)
        .forEach((script) => {
          const newScript = document.createElement("script");
          newScript.dataset.cookiecategory = script.dataset.cookiecategory;
          if (script.src) {
            newScript.src = script.src;
          }
          if (script.textContent) {
            newScript.textContent = script.textContent;
            newScript.type = "text/javascript";
          }
          if (script.async) {
            newScript.async = script.async;
          }
          document.getElementsByTagName("head")[0].appendChild(newScript);
        });
    }
  });
};

if (cookieConsent || window.language !== "en") {
  // console.log(cookieConsent)
  if (window.language !== "en") {
    cookieConsent = JSON.stringify({
      // functional: true,
      settings: true,
      analytics: true,
      marketing: true,
    });
  }
  cookieConsent = JSON.parse(cookieConsent);
  cookieConsent.functional = true;
  if (document.readyState !== "loading") {
    loadScripts();
  } else {
    document.addEventListener("DOMContentLoaded", function () {
      loadScripts();
    });
  }
}

if (document.querySelector(".js-cookie-open")) {
  document
    .querySelector(".js-cookie-open")
    .addEventListener("click", function (e) {
      e.preventDefault();
      document.querySelector("#cookie-consent").classList.toggle("open");
    });
}

if (document.querySelector(".js-accept-cookies")) {
  document
    .querySelector(".js-accept-cookies")
    .addEventListener("click", function (e) {
      e.preventDefault();
      const rulesInputs = document.querySelectorAll(
        '.cookie-consent-toggles input[type="checkbox"]'
      );
      const rules = {};
      rulesInputs.forEach((input) => {
        rules[input.name] = input.checked;
      });
      delete rules.functional;
      // console.log(rules);
      Cookies.set(cookieConsentName, JSON.stringify(rules), { expires: 365 });
      cookieConsent = Cookies.get(cookieConsentName);
      loadScripts();
    });
}

if (document.querySelector(".js-reject-cookies")) {
  document
    .querySelector(".js-reject-cookies")
    .addEventListener("click", function (e) {
      e.preventDefault();
      Cookies.remove(cookieConsentName, { path: "/" });
      document.querySelector('.cookie-consent').style.display = 'none';
    });
}
